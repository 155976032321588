exports.components = {
  "component---src-pages-fall-21-bonfire-js": () => import("./../../../src/pages/fall21/bonfire.js" /* webpackChunkName: "component---src-pages-fall-21-bonfire-js" */),
  "component---src-pages-fall-21-clarifi-js": () => import("./../../../src/pages/fall21/clarifi.js" /* webpackChunkName: "component---src-pages-fall-21-clarifi-js" */),
  "component---src-pages-fall-21-commoncents-js": () => import("./../../../src/pages/fall21/commoncents.js" /* webpackChunkName: "component---src-pages-fall-21-commoncents-js" */),
  "component---src-pages-fall-21-counselab-js": () => import("./../../../src/pages/fall21/counselab.js" /* webpackChunkName: "component---src-pages-fall-21-counselab-js" */),
  "component---src-pages-fall-21-ignite-js": () => import("./../../../src/pages/fall21/ignite.js" /* webpackChunkName: "component---src-pages-fall-21-ignite-js" */),
  "component---src-pages-fall-21-js": () => import("./../../../src/pages/fall21.js" /* webpackChunkName: "component---src-pages-fall-21-js" */),
  "component---src-pages-fall-21-penntix-js": () => import("./../../../src/pages/fall21/penntix.js" /* webpackChunkName: "component---src-pages-fall-21-penntix-js" */),
  "component---src-pages-fall-21-popplex-js": () => import("./../../../src/pages/fall21/popplex.js" /* webpackChunkName: "component---src-pages-fall-21-popplex-js" */),
  "component---src-pages-fall-22-js": () => import("./../../../src/pages/fall22.js" /* webpackChunkName: "component---src-pages-fall-22-js" */),
  "component---src-pages-fall-22-respond-crisis-js": () => import("./../../../src/pages/fall22/respond-crisis.js" /* webpackChunkName: "component---src-pages-fall-22-respond-crisis-js" */),
  "component---src-pages-fall-22-ureka-health-js": () => import("./../../../src/pages/fall22/ureka-health.js" /* webpackChunkName: "component---src-pages-fall-22-ureka-health-js" */),
  "component---src-pages-hits-js": () => import("./../../../src/pages/hits.js" /* webpackChunkName: "component---src-pages-hits-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-spring-21-arthub-js": () => import("./../../../src/pages/spring21/arthub.js" /* webpackChunkName: "component---src-pages-spring-21-arthub-js" */),
  "component---src-pages-spring-21-clarifi-js": () => import("./../../../src/pages/spring21/clarifi.js" /* webpackChunkName: "component---src-pages-spring-21-clarifi-js" */),
  "component---src-pages-spring-21-emojifier-js": () => import("./../../../src/pages/spring21/emojifier.js" /* webpackChunkName: "component---src-pages-spring-21-emojifier-js" */),
  "component---src-pages-spring-21-habitat-js": () => import("./../../../src/pages/spring21/habitat.js" /* webpackChunkName: "component---src-pages-spring-21-habitat-js" */),
  "component---src-pages-spring-21-js": () => import("./../../../src/pages/spring21.js" /* webpackChunkName: "component---src-pages-spring-21-js" */),
  "component---src-pages-spring-21-mystery-at-museum-js": () => import("./../../../src/pages/spring21/mystery-at-museum.js" /* webpackChunkName: "component---src-pages-spring-21-mystery-at-museum-js" */),
  "component---src-pages-spring-21-mystery-music-js": () => import("./../../../src/pages/spring21/mystery-music.js" /* webpackChunkName: "component---src-pages-spring-21-mystery-music-js" */),
  "component---src-pages-spring-21-penntitions-js": () => import("./../../../src/pages/spring21/penntitions.js" /* webpackChunkName: "component---src-pages-spring-21-penntitions-js" */),
  "component---src-pages-spring-21-poga-js": () => import("./../../../src/pages/spring21/poga.js" /* webpackChunkName: "component---src-pages-spring-21-poga-js" */),
  "component---src-pages-spring-22-anxie-tea-js": () => import("./../../../src/pages/spring22/anxie-tea.js" /* webpackChunkName: "component---src-pages-spring-22-anxie-tea-js" */),
  "component---src-pages-spring-22-artz-philly-js": () => import("./../../../src/pages/spring22/artz-philly.js" /* webpackChunkName: "component---src-pages-spring-22-artz-philly-js" */),
  "component---src-pages-spring-22-gaia-ai-js": () => import("./../../../src/pages/spring22/gaia-ai.js" /* webpackChunkName: "component---src-pages-spring-22-gaia-ai-js" */),
  "component---src-pages-spring-22-interviews-live-js": () => import("./../../../src/pages/spring22/interviews-live.js" /* webpackChunkName: "component---src-pages-spring-22-interviews-live-js" */),
  "component---src-pages-spring-22-js": () => import("./../../../src/pages/spring22.js" /* webpackChunkName: "component---src-pages-spring-22-js" */),
  "component---src-pages-spring-22-matchamaker-js": () => import("./../../../src/pages/spring22/matchamaker.js" /* webpackChunkName: "component---src-pages-spring-22-matchamaker-js" */),
  "component---src-pages-spring-22-penn-events-js": () => import("./../../../src/pages/spring22/penn-events.js" /* webpackChunkName: "component---src-pages-spring-22-penn-events-js" */),
  "component---src-pages-spring-22-penn-playbook-js": () => import("./../../../src/pages/spring22/penn-playbook.js" /* webpackChunkName: "component---src-pages-spring-22-penn-playbook-js" */),
  "component---src-pages-spring-23-berater-js": () => import("./../../../src/pages/spring23/berater.js" /* webpackChunkName: "component---src-pages-spring-23-berater-js" */),
  "component---src-pages-spring-23-catz-js": () => import("./../../../src/pages/spring23/catz.js" /* webpackChunkName: "component---src-pages-spring-23-catz-js" */),
  "component---src-pages-spring-23-coffee-chat-js": () => import("./../../../src/pages/spring23/coffee-chat.js" /* webpackChunkName: "component---src-pages-spring-23-coffee-chat-js" */),
  "component---src-pages-spring-23-find-me-food-js": () => import("./../../../src/pages/spring23/find-me-food.js" /* webpackChunkName: "component---src-pages-spring-23-find-me-food-js" */),
  "component---src-pages-spring-23-js": () => import("./../../../src/pages/spring23.js" /* webpackChunkName: "component---src-pages-spring-23-js" */),
  "component---src-pages-spring-23-procedural-city-js": () => import("./../../../src/pages/spring23/procedural-city.js" /* webpackChunkName: "component---src-pages-spring-23-procedural-city-js" */),
  "component---src-pages-spring-23-spark-in-photos-js": () => import("./../../../src/pages/spring23/spark-in-photos.js" /* webpackChunkName: "component---src-pages-spring-23-spark-in-photos-js" */),
  "component---src-pages-spring-23-studious-js": () => import("./../../../src/pages/spring23/studious.js" /* webpackChunkName: "component---src-pages-spring-23-studious-js" */)
}

